<template>
  <v-card elevation="4">
    <v-card-text class="d-flex justify-space-between">
      <div class="d-flex flex-column justify-space-between">
        <v-skeleton-loader width="80px" type="heading" class="text mb-2" />
        <v-skeleton-loader width="400px" type="text" class="text" />
      </div>
      <div class="d-flex align-center">
        <v-skeleton-loader class="switch" type="heading" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.v-skeleton-loader.text::v-deep {
  .v-skeleton-loader__heading {
    max-height: 10px;
    margin: 7px 0;
    margin-right: 0px !important;
    width: 100%;
  }
  .v-skeleton-loader__text {
    max-height: 8px;
    margin: 5px 0;
  }
}
.v-skeleton-loader.switch::v-deep {
  .v-skeleton-loader__heading {
    height: 28px;
    width: 45px;
    border-radius: 100px;
  }
}
</style>