<template>
  <div
    class="switch-container pl-1 d-flex"
    :class="start ? 'justify-start' : 'justify-center ml-2 '"
  >
    <v-switch
      :id="attachId"
      :input-value="value"
      class="mt-0 pt-0"
      hide-details=""
      flat
      :disabled="disabled"
      :loading="loading"
    >
    </v-switch>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    attachId: String,
    disabled: Boolean,
    loading: Boolean,
    start: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.switch-container {
  cursor: pointer;
  &--disabled {
    pointer-events: none;
  }
  .v-input--switch {
    pointer-events: none;
  }
}
.v-input.v-input--switch::v-deep {
  .v-input__control .v-input__slot {
    min-height: 40px;
  }
}
</style>