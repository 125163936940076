<template>
  <div class="ssl-indicator d-flex align-start">
    <loader :noMargins="true" :size="16" v-if="fetching" color="primary" />

    <template v-else-if="isIcon">
      <v-tooltip
        open-delay="150"
        bottom
        z-index="99"
        offset-overflow
        nudge-bottom="12px"
        transition="custom-tooltip"
      >
        <template v-slot:activator="{ on, attrs }">
          <template v-if="ssl.certificate_installed">
            <v-icon
              v-on="on"
              v-bind="attrs"
              :size="small ? '12' : '16'"
              color="success"
              v-if="!ssl.certificate.self_signed && ssl.certificate.name_match"
            >
              $lock
            </v-icon>
            <v-icon
              v-on="on"
              v-bind="attrs"
              :size="small ? '12' : '16'"
              color="error"
              v-else
            >
              $lock
            </v-icon>
          </template>
          <v-icon
            :size="small ? '12' : '16'"
            color="error"
            v-else
            v-on="on"
            v-bind="attrs"
          >
            $unlock
          </v-icon>
          <span :class="textClass" v-if="text" v-on="on" v-bind="attrs">
            {{ text }}
          </span>
        </template>
        <span v-if="ssl.certificate && ssl.certificate.self_signed">
          {{ $t('tooltip.instance.ssl.selfSigned') }}
        </span>
        <span v-else-if="ssl.certificate && ssl.certificate.name_match">
          {{ $t('tooltip.instance.ssl.secured') }}
        </span>
        <span v-else>{{ $t('tooltip.instance.ssl.enabled') }} </span>
      </v-tooltip>
    </template>
    <template v-else>
      <span
        class="p-5 font-weight-600 line-16 d-flex align-center"
        v-if="ssl.certificate_installed"
      >
        <template v-if="!ssl.certificate.self_signed">
          <v-icon
            size="16"
            :color="ssl.certificate.name_match ? 'success' : 'error'"
          >
            $lock</v-icon
          >
          <span class="heading--text p-3 ml-2 line-16">
            {{ ssl.certificate.issuer_name }}
          </span>
        </template>
        <template v-else>
          <v-icon size="16" color="error">$lock</v-icon>
          <span class="heading--text p-3 ml-2 line-16">{{ $t('general.sslProvider.selfSigned') }}</span>
        </template>
      </span>
      <span class="error--text font-weight-bold d-flex align-center" v-else>
        <v-icon color="error" size="16">$unlock</v-icon>
        <span class="p-3 ml-2 line-16">{{ $t('message.status.disabled') }}</span>
      </span>
    </template>
  </div>
</template>

<script>
import Loader from "../Loader.vue";

export default {
  components: {
    Loader,
  },
  props: {
    textClass: String,
    fetching: Boolean,
    small: {
      type: Boolean,
      default: false,
    },
    ssl: {
      type: Object,
    },
    text: {
      type: String,
      default: "",
    },
    isIcon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
